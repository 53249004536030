<template>
  <!-- 新建banner页面 -->
  <div class="positionT0L0">
    <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="{ span: 2 }" :wrapper-col="{ span: 10 }">
      <a-form-model-item label="海报名称" prop="name" class="por">
        <a-input v-model="form.name" :maxLength="32" placeholder="请输入海报名称">
          <div slot="addonAfter">{{ form.name.length }}/32</div>
        </a-input>
      </a-form-model-item>

      <a-form-model-item label="海报图片" prop="imageUrl">
        <div class="poster">
          <UpdateImg @avatarfns="data => form.imageUrl = data[0]" :imageUrlNum="1" :imgUrl="form.imageUrl ? [form.imageUrl] : []" :urlSize="1024" />
          <div>
            <span>应用于签到分享海报</span>
            <span>建议尺寸600px * 860px，JPG.PNG.格式 图片小于500k</span>
          </div>
        </div>
      </a-form-model-item>

      <a-form-model-item label="日期" prop="effectiveDate">
        <a-date-picker v-model="form.effectiveDate" format="YYYY-MM-DD" :disabled-date="disabledDate" placeholder="选择日期" />
      </a-form-model-item>
      <a-form-model-item label="跳转方式" prop="targetType" required>
        <a-radio-group v-model="form.targetType">
          <a-radio :value="1"> 课程 </a-radio>
          <a-radio :value="2"> 音频 </a-radio>
          <a-radio :value="3"> 视频 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      
      <a-form-model-item v-if="+form.targetType === 1" label="选择课程"  prop="businessNo">
        <a-button type="primary" @click="visible = true">{{ tableList.length === 0 ? '添加课程' : '修改课程'}}</a-button>
        <div v-if="tableList.length > 0">
          <ProductCard :table-list="tableList" />
        </div>
      </a-form-model-item>
      <a-form-model-item v-else label="背景图片" prop="background">
        <div class="poster">
          <UpdateImg @avatarfns="data => form.background = data[0]" :imageUrlNum="1" :imgUrl="form.background ? [form.background] : []" />
          <div>
            <span>应用于H5背景图</span>
            <span>建议尺寸686px * 1190px，JPG.PNG.格式 图片小于500k</span>
          </div>
        </div>
      </a-form-model-item>

      <a-form-model-item v-if="form.targetType === 2" label="音频上传" prop="attachementUrl">
        <QiniuVideoAudio
          :oldFileName="form.attachementName"
          :type="1"
          @videoFn="getAudioInfo"
        />
        <div style="font-weight: 600">格式支持mp3，为保证音频加载与播放的流畅性，建议上传大小不超过500M。</div>
      </a-form-model-item>
      <a-form-model-item v-if="form.targetType === 3" label="视频上传" prop="attachementUrl">
        <QiniuVideoAudio
          :oldFileName="form.attachementUrl"
          :type="2"
          id="videoUpdate"
          @videoFn="obj => form.attachementUrl = obj.fileUrl"
        />
        <div style="font-weight: 600">格式支持mp4，为保证音频加载与播放的流畅性，建议上传大小不超过500M。</div>
      </a-form-model-item>
    </a-form-model>

    <FooterToolBar :collapsed="sideCollapsed">
      <a-button class="margin_right60" @click="() => $router.go(-1)">返回</a-button>
      <a-button type="primary" @click="keepClick" :loading="keepLoading" :disabled="keepLoading">保存</a-button>
    </FooterToolBar>

    <RadioModal
      v-if="visible"
      :modalVisible="visible"
      :tabType="form.businessType"
      :type="0"
      :selectedRowKeys="selectedRowKeys"
      @cancel="visible = false"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import RadioModal from '@/components/courseMember/RadioModal'
import { baseMixin } from '@/store/app-mixin'
import FooterToolBar from '@/components/FooterToolbar'
import moment from "moment"
import UpdateImg from '@/components/file/updataImg'
import { disabledDate } from '@/utils/tools'
import ProductCard from '@/components/product/ProductCard'
import QiniuVideoAudio from '@/components/file/QiniuVideoAudio.vue'
import { addShopPlaybill, editShopPlaybillList, getOneShopPlaybill } from '@/request/api/indexManage'
export default {
  mixins: [ baseMixin ],
  components: { FooterToolBar,RadioModal, UpdateImg, ProductCard, QiniuVideoAudio },
  data() {
    return {
      keepLoading: false, // 保存的按钮是否加载
      form: {
        id: '',
        name: '',
        imageUrl: '',
        businessNo: '',
        businessType: 1,
        effectiveDate: '',
        state: 2,
        targetType: 1,
        background: '',
        attachementUrl: '',
        appendixTime: 0,
        attachementName: ''
      },
      rules: {
        name: [
          {required: true, message: "输入的内容不能为空", trigger: "blur"},
          {max: 32, message: "长度最长为32个", trigger: "blur"},
        ],
        imageUrl:[{ required: true, message: "请上传海报图片", trigger: ['blur', 'change']}],
        businessNo:[{ required: true, message: "请选择课程", trigger: ['blur', 'change']}],
        effectiveDate: [{ required: true, message: '请选择时间', trigger: ['change', 'blur']}],
        background: [{ required: true, message: "请上传背景图片", trigger: ['blur', 'change']}],
        attachementUrl: [{ required: true, message: "请上传音频", trigger: ['blur', 'change']}],
      },
      visible: false,
      mdl: null,
      selectedRowKeys: [],
      disabledDate,
      tableList: []
    };
  },
  created() {
    const id = +this.$route.params.id
    if (id !== 0) {
      this.getDetail(id)
    }
  },
  watch: {
    'form.targetType'(val) {
      if (val === 2) this.rules.attachementUrl = [{ required: true, message: "请上传音频", trigger: ['blur', 'change']}]
      if (val === 3) this.rules.attachementUrl = [{ required: true, message: "请上传视频", trigger: ['blur', 'change']}]
    }
  },
  methods: {
    moment,
    getAudioInfo(obj) {
      this.form.attachementUrl = obj.fileUrl
      this.form.appendixTime = Math.round(Number(obj.videoTime))
      this.form.attachementName = obj.oldFileName
    },
    async getDetail(id) {
      const { code, data } = await getOneShopPlaybill({ id: id })
      if (code !== 200) return
      Object.assign(this.form, data)
      if (+data.targetType === 1) {
        const courseInfo = {
          businessName: data.businessName,
          businessType: data.businessType,
          coverUrl: data.coverUrl,
          price: data.price
        }
        this.tableList = [courseInfo]
      }
    },

    // 弹窗中确认按钮事件
    handleOk(selectedRowKeys, selectedRows) {
      this.visible = false
      if (selectedRowKeys.length === 0) return
      this.form.businessNo = selectedRows.courseNo
      this.tableList = [selectedRows]
      this.selectedRowKeys = selectedRowKeys
    },
    // 保存的按钮
    keepClick() {
      this.keepLoading = true
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.form.effectiveDate = moment(this.form.effectiveDate).format('yyyy-MM-DD')
          !!this.form.id ? await this.edit() : await this.add()
        }
        this.keepLoading = false
      })
    },
    async add() {
      delete this.form.id
      const { code } = await addShopPlaybill(this.form)
      if (code !== 200) return
      this.$message.success('添加成功')
      this.$router.push('/indexManage/signPoster')
    },
    async edit() {
      const { code } = await editShopPlaybillList(this.form)
      if (code !== 200) return
      this.$message.success('修改成功')
      this.$router.push('/indexManage/signPoster')
    },
  }
}
</script>

<style lang="less" scoped>
.poster {
  display: flex;
  align-items: center;
  span {
    display: block;
    margin-left: 20px;
    font-weight: bold;
    line-height: 30px;
  }
}
</style>
